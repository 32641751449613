import { createStyles } from "@material-ui/core";
import theme from "../../theme";

export default createStyles({

  root: {
    padding: 10
  },

  marginTop: {
    marginTop: theme.spacing(2)
  },

  checkbox: {

  },

  notificationsOnContainer: {

  }

});