/* tslint:disable */
/* eslint-disable */
/**
 * CityLoops API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    SearchHound,
    SearchHoundFromJSON,
    SearchHoundToJSON,
} from '../models';

export interface CreateSearchHoundRequest {
    searchHound: SearchHound;
}

export interface DeleteSearchHoundRequest {
    searchHoundId: string;
}

export interface FindSearchHoundRequest {
    searchHoundId: string;
}

export interface ListSearchHoundsRequest {
    userId?: string;
    categoryId?: string;
    notificationsOn?: boolean;
}

export interface UpdateSearchHoundRequest {
    searchHound: SearchHound;
    searchHoundId: string;
}

/**
 * no description
 */
export class SearchHoundsApi extends runtime.BaseAPI {

    /**
     * Creates new search hound
     * Create a search hound.
     */
    async createSearchHoundRaw(requestParameters: CreateSearchHoundRequest): Promise<runtime.ApiResponse<SearchHound>> {
        if (requestParameters.searchHound === null || requestParameters.searchHound === undefined) {
            throw new runtime.RequiredError('searchHound','Required parameter requestParameters.searchHound was null or undefined when calling createSearchHound.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/searchHounds`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchHoundToJSON(requestParameters.searchHound),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchHoundFromJSON(jsonValue));
    }

    /**
     * Creates new search hound
     * Create a search hound.
     */
    async createSearchHound(requestParameters: CreateSearchHoundRequest): Promise<SearchHound> {
        const response = await this.createSearchHoundRaw(requestParameters);
        return await response.value();
    }

    /**
     * Deletes search hound
     * Deletes a search hound.
     */
    async deleteSearchHoundRaw(requestParameters: DeleteSearchHoundRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.searchHoundId === null || requestParameters.searchHoundId === undefined) {
            throw new runtime.RequiredError('searchHoundId','Required parameter requestParameters.searchHoundId was null or undefined when calling deleteSearchHound.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/searchHounds/{searchHoundId}`.replace(`{${"searchHoundId"}}`, encodeURIComponent(String(requestParameters.searchHoundId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes search hound
     * Deletes a search hound.
     */
    async deleteSearchHound(requestParameters: DeleteSearchHoundRequest): Promise<void> {
        await this.deleteSearchHoundRaw(requestParameters);
    }

    /**
     * Finds a search hound by id
     * Find a search hound.
     */
    async findSearchHoundRaw(requestParameters: FindSearchHoundRequest): Promise<runtime.ApiResponse<SearchHound>> {
        if (requestParameters.searchHoundId === null || requestParameters.searchHoundId === undefined) {
            throw new runtime.RequiredError('searchHoundId','Required parameter requestParameters.searchHoundId was null or undefined when calling findSearchHound.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/searchHounds/{searchHoundId}`.replace(`{${"searchHoundId"}}`, encodeURIComponent(String(requestParameters.searchHoundId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchHoundFromJSON(jsonValue));
    }

    /**
     * Finds a search hound by id
     * Find a search hound.
     */
    async findSearchHound(requestParameters: FindSearchHoundRequest): Promise<SearchHound> {
        const response = await this.findSearchHoundRaw(requestParameters);
        return await response.value();
    }

    /**
     * Lists search hounds
     * List search hounds.
     */
    async listSearchHoundsRaw(requestParameters: ListSearchHoundsRequest): Promise<runtime.ApiResponse<Array<SearchHound>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        if (requestParameters.categoryId !== undefined) {
            queryParameters['categoryId'] = requestParameters.categoryId;
        }

        if (requestParameters.notificationsOn !== undefined) {
            queryParameters['notificationsOn'] = requestParameters.notificationsOn;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/searchHounds`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SearchHoundFromJSON));
    }

    /**
     * Lists search hounds
     * List search hounds.
     */
    async listSearchHounds(requestParameters: ListSearchHoundsRequest): Promise<Array<SearchHound>> {
        const response = await this.listSearchHoundsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates search hound
     * Updates a search hound.
     */
    async updateSearchHoundRaw(requestParameters: UpdateSearchHoundRequest): Promise<runtime.ApiResponse<SearchHound>> {
        if (requestParameters.searchHound === null || requestParameters.searchHound === undefined) {
            throw new runtime.RequiredError('searchHound','Required parameter requestParameters.searchHound was null or undefined when calling updateSearchHound.');
        }

        if (requestParameters.searchHoundId === null || requestParameters.searchHoundId === undefined) {
            throw new runtime.RequiredError('searchHoundId','Required parameter requestParameters.searchHoundId was null or undefined when calling updateSearchHound.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/searchHounds/{searchHoundId}`.replace(`{${"searchHoundId"}}`, encodeURIComponent(String(requestParameters.searchHoundId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SearchHoundToJSON(requestParameters.searchHound),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchHoundFromJSON(jsonValue));
    }

    /**
     * Updates search hound
     * Updates a search hound.
     */
    async updateSearchHound(requestParameters: UpdateSearchHoundRequest): Promise<SearchHound> {
        const response = await this.updateSearchHoundRaw(requestParameters);
        return await response.value();
    }

}
