import { createStyles } from "@material-ui/core";

export default createStyles({

  loaderWrapper: {
    width: "100%",
    height: "100%",
    minWidth: 250,
    minHeight: 200,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },

  cancelButton: {

  }

});