/* tslint:disable */
/* eslint-disable */
/**
 * CityLoops API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Item,
    ItemFromJSON,
    ItemToJSON,
    ItemType,
    ItemTypeFromJSON,
    ItemTypeToJSON,
} from '../models';

export interface CreateItemRequest {
    item: Item;
}

export interface DeleteItemRequest {
    itemId: string;
}

export interface FindItemRequest {
    itemId: string;
}

export interface ListItemsRequest {
    userId?: string;
    categoryId?: string;
    firstResult?: number;
    maxResults?: number;
    sortByDateOldestFirst?: boolean;
    includeExpired?: boolean;
    itemType?: ItemType;
}

export interface UpdateItemRequest {
    item: Item;
    itemId: string;
}

/**
 * no description
 */
export class ItemsApi extends runtime.BaseAPI {

    /**
     * Creates new item
     * Create an item.
     */
    async createItemRaw(requestParameters: CreateItemRequest): Promise<runtime.ApiResponse<Item>> {
        if (requestParameters.item === null || requestParameters.item === undefined) {
            throw new runtime.RequiredError('item','Required parameter requestParameters.item was null or undefined when calling createItem.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/items`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ItemToJSON(requestParameters.item),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ItemFromJSON(jsonValue));
    }

    /**
     * Creates new item
     * Create an item.
     */
    async createItem(requestParameters: CreateItemRequest): Promise<Item> {
        const response = await this.createItemRaw(requestParameters);
        return await response.value();
    }

    /**
     * Deletes item
     * Deletes an item.
     */
    async deleteItemRaw(requestParameters: DeleteItemRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.itemId === null || requestParameters.itemId === undefined) {
            throw new runtime.RequiredError('itemId','Required parameter requestParameters.itemId was null or undefined when calling deleteItem.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/items/{itemId}`.replace(`{${"itemId"}}`, encodeURIComponent(String(requestParameters.itemId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes item
     * Deletes an item.
     */
    async deleteItem(requestParameters: DeleteItemRequest): Promise<void> {
        await this.deleteItemRaw(requestParameters);
    }

    /**
     * Finds an item by id
     * Find an item.
     */
    async findItemRaw(requestParameters: FindItemRequest): Promise<runtime.ApiResponse<Item>> {
        if (requestParameters.itemId === null || requestParameters.itemId === undefined) {
            throw new runtime.RequiredError('itemId','Required parameter requestParameters.itemId was null or undefined when calling findItem.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/items/{itemId}`.replace(`{${"itemId"}}`, encodeURIComponent(String(requestParameters.itemId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ItemFromJSON(jsonValue));
    }

    /**
     * Finds an item by id
     * Find an item.
     */
    async findItem(requestParameters: FindItemRequest): Promise<Item> {
        const response = await this.findItemRaw(requestParameters);
        return await response.value();
    }

    /**
     * Lists items
     * List items.
     */
    async listItemsRaw(requestParameters: ListItemsRequest): Promise<runtime.ApiResponse<Array<Item>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        if (requestParameters.categoryId !== undefined) {
            queryParameters['categoryId'] = requestParameters.categoryId;
        }

        if (requestParameters.firstResult !== undefined) {
            queryParameters['firstResult'] = requestParameters.firstResult;
        }

        if (requestParameters.maxResults !== undefined) {
            queryParameters['maxResults'] = requestParameters.maxResults;
        }

        if (requestParameters.sortByDateOldestFirst !== undefined) {
            queryParameters['sortByDateOldestFirst'] = requestParameters.sortByDateOldestFirst;
        }

        if (requestParameters.includeExpired !== undefined) {
            queryParameters['includeExpired'] = requestParameters.includeExpired;
        }

        if (requestParameters.itemType !== undefined) {
            queryParameters['itemType'] = requestParameters.itemType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/items`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ItemFromJSON));
    }

    /**
     * Lists items
     * List items.
     */
    async listItems(requestParameters: ListItemsRequest): Promise<Array<Item>> {
        const response = await this.listItemsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates item
     * Updates an item.
     */
    async updateItemRaw(requestParameters: UpdateItemRequest): Promise<runtime.ApiResponse<Item>> {
        if (requestParameters.item === null || requestParameters.item === undefined) {
            throw new runtime.RequiredError('item','Required parameter requestParameters.item was null or undefined when calling updateItem.');
        }

        if (requestParameters.itemId === null || requestParameters.itemId === undefined) {
            throw new runtime.RequiredError('itemId','Required parameter requestParameters.itemId was null or undefined when calling updateItem.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/items/{itemId}`.replace(`{${"itemId"}}`, encodeURIComponent(String(requestParameters.itemId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ItemToJSON(requestParameters.item),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ItemFromJSON(jsonValue));
    }

    /**
     * Updates item
     * Updates an item.
     */
    async updateItem(requestParameters: UpdateItemRequest): Promise<Item> {
        const response = await this.updateItemRaw(requestParameters);
        return await response.value();
    }

}
