/* tslint:disable */
/* eslint-disable */
/**
 * CityLoops API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Coordinates,
    CoordinatesFromJSON,
    CoordinatesFromJSONTyped,
    CoordinatesToJSON,
} from './';

/**
 * User object
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    readonly id?: string;
    /**
     * User name
     * @type {string}
     * @memberof User
     */
    name: string;
    /**
     * User address
     * @type {string}
     * @memberof User
     */
    address: string;
    /**
     * User email
     * @type {string}
     * @memberof User
     */
    email: string;
    /**
     * User phone number
     * @type {string}
     * @memberof User
     */
    phoneNumber: string;
    /**
     * Is this user company account
     * @type {boolean}
     * @memberof User
     */
    companyAccount: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    companyId?: string;
    /**
     * 
     * @type {Coordinates}
     * @memberof User
     */
    coordinates?: Coordinates;
    /**
     * Location description
     * @type {string}
     * @memberof User
     */
    officeInfo?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    password?: string;
    /**
     * Has this user been verified/allowed to use online payment
     * @type {boolean}
     * @memberof User
     */
    verified?: boolean;
    /**
     * Is this user prioritized over other users
     * @type {boolean}
     * @memberof User
     */
    priorityUser?: boolean;
    /**
     * User description
     * @type {string}
     * @memberof User
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    logoUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    readonly creatorId?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    readonly lastModifierId?: string;
    /**
     * Created date
     * @type {Date}
     * @memberof User
     */
    readonly createdAt?: Date;
    /**
     * Date modified
     * @type {Date}
     * @memberof User
     */
    readonly modifiedAt?: Date;
}

export function UserFromJSON(json: any): User {
    return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(json: any, ignoreDiscriminator: boolean): User {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'address': json['address'],
        'email': json['email'],
        'phoneNumber': json['phoneNumber'],
        'companyAccount': json['companyAccount'],
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'coordinates': !exists(json, 'coordinates') ? undefined : CoordinatesFromJSON(json['coordinates']),
        'officeInfo': !exists(json, 'officeInfo') ? undefined : json['officeInfo'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'verified': !exists(json, 'verified') ? undefined : json['verified'],
        'priorityUser': !exists(json, 'priorityUser') ? undefined : json['priorityUser'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'logoUrl': !exists(json, 'logoUrl') ? undefined : json['logoUrl'],
        'creatorId': !exists(json, 'creatorId') ? undefined : json['creatorId'],
        'lastModifierId': !exists(json, 'lastModifierId') ? undefined : json['lastModifierId'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'modifiedAt': !exists(json, 'modifiedAt') ? undefined : (new Date(json['modifiedAt'])),
    };
}

export function UserToJSON(value?: User | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'address': value.address,
        'email': value.email,
        'phoneNumber': value.phoneNumber,
        'companyAccount': value.companyAccount,
        'companyId': value.companyId,
        'coordinates': CoordinatesToJSON(value.coordinates),
        'officeInfo': value.officeInfo,
        'password': value.password,
        'verified': value.verified,
        'priorityUser': value.priorityUser,
        'description': value.description,
        'logoUrl': value.logoUrl,
    };
}


