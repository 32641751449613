import { createStyles } from "@material-ui/core";

export default createStyles({

  root: { },

  textField: {
    marginBottom: 20
  }

});