/* tslint:disable */
/* eslint-disable */
/**
 * CityLoops API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Search hound object
 * @export
 * @interface SearchHound
 */
export interface SearchHound {
    /**
     * 
     * @type {string}
     * @memberof SearchHound
     */
    readonly id?: string;
    /**
     * Search hound name
     * @type {string}
     * @memberof SearchHound
     */
    name: string;
    /**
     * Are notifications on or off
     * @type {boolean}
     * @memberof SearchHound
     */
    notificationsOn: boolean;
    /**
     * Category id
     * @type {string}
     * @memberof SearchHound
     */
    categoryId: string;
    /**
     * Owner of this search hound
     * @type {string}
     * @memberof SearchHound
     */
    userId: string;
    /**
     * When seach hound expires
     * @type {Date}
     * @memberof SearchHound
     */
    expires: Date;
    /**
     * Minimum price of the item
     * @type {number}
     * @memberof SearchHound
     */
    minPrice?: number;
    /**
     * Maximum price of the item
     * @type {number}
     * @memberof SearchHound
     */
    maxPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchHound
     */
    readonly creatorId?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchHound
     */
    readonly lastModifierId?: string;
    /**
     * Created date
     * @type {Date}
     * @memberof SearchHound
     */
    readonly createdAt?: Date;
    /**
     * Date modified
     * @type {Date}
     * @memberof SearchHound
     */
    readonly modifiedAt?: Date;
}

export function SearchHoundFromJSON(json: any): SearchHound {
    return SearchHoundFromJSONTyped(json, false);
}

export function SearchHoundFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchHound {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'notificationsOn': json['notificationsOn'],
        'categoryId': json['categoryId'],
        'userId': json['userId'],
        'expires': (new Date(json['expires'])),
        'minPrice': !exists(json, 'minPrice') ? undefined : json['minPrice'],
        'maxPrice': !exists(json, 'maxPrice') ? undefined : json['maxPrice'],
        'creatorId': !exists(json, 'creatorId') ? undefined : json['creatorId'],
        'lastModifierId': !exists(json, 'lastModifierId') ? undefined : json['lastModifierId'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'modifiedAt': !exists(json, 'modifiedAt') ? undefined : (new Date(json['modifiedAt'])),
    };
}

export function SearchHoundToJSON(value?: SearchHound | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'notificationsOn': value.notificationsOn,
        'categoryId': value.categoryId,
        'userId': value.userId,
        'expires': (value.expires.toISOString()),
        'minPrice': value.minPrice,
        'maxPrice': value.maxPrice,
    };
}


