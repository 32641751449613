import { createStyles } from "@material-ui/core";

export default createStyles({

  root: { },

  propertiesContainer: {
    width: "100%",
    paddingTop: 10
  },

  propertyField: {
    marginTop: 20
  }

});