/* tslint:disable */
/* eslint-disable */
/**
 * CityLoops API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Item type enum
 * @export
 * @enum {string}
 */
export enum ItemType {
    SELL = 'SELL',
    BUY = 'BUY',
    RENT = 'RENT'
}

export function ItemTypeFromJSON(json: any): ItemType {
    return ItemTypeFromJSONTyped(json, false);
}

export function ItemTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ItemType {
    return json as ItemType;
}

export function ItemTypeToJSON(value?: ItemType | null): any {
    return value as any;
}

